import Typography from 'typography'
// import WerkslogTheme from 'typography-theme-noriega'
// import WerkslogTheme from 'typography-theme-wordpress-2016'
import WerkslogTheme from 'typography-theme-parnassus'
//WerkslogTheme.headerFontFamily = ['Fira Sans']
WerkslogTheme.headerFontFamily = ['Merriweather Sans', 'sans-serif']

WerkslogTheme.overrideThemeStyles = () => {
  return {
    'h1, h2, h3, h4, h5': {
      color: '#917c6fff',
    },
    'h1': {
      fontFamily: ['Merriweather Sans', 'sans-serif'].join(','),
      fontSize: `2.0em`,
    },
    'a, a.gatsby-resp-image-link': {
      boxShadow: `none`,
    },
  }
}

// delete KirkhamTheme.googleFonts

const typography = new Typography(WerkslogTheme)

// Hot reload typography in development.
if (process.env.NODE_ENV !== `production`) {
  typography.injectStyles()
}

export default typography
export const rhythm = typography.rhythm
export const scale = typography.scale
