import React from 'react'
import { Link, StaticQuery, graphql } from 'gatsby'
import Image from 'gatsby-image'
import { rhythm, scale } from '../utils/typography'
import { css } from '@emotion/core'

class Layout extends React.Component {
  render() {
    const { location, children } = this.props
    const rootPath = `${__PATH_PREFIX__}/`
    let header, headerLink

    headerLink = (
      <StaticQuery
        query = {logoQuery}
        render = { data => (
          <Link
            css={css`
              display: flex;
              justify-content: space-between;
              box-shadow: none;
              text-decoration: none;
              color: #917c6fff;
              font-family: 'Merriweather Sans';
              ${'' /* font-variant: small-caps; */}
            `}
            to={`/`}
          >
            <div>
              <span css={css`color: #ff9955ff;`}>MAKE</span>WERKS          
            </div>
            <Image
              fluid={data.makewerksLogo.childImageSharp.fluid}
              alt="makewerks logo"
              css={css`
                margin-right: 0;
                margin-bottom: 0;
                min-width: ${location.pathname === rootPath ? 40 : 30}px;
              `}
            />
          </Link>
        )}
      />
    )

    if (location.pathname === rootPath) {
      header = (
        <h1
          css={css`
            margin-top: 0;
            margin-bottom: ${rhythm(0.6)};
          `}
        >
        {headerLink}
        </h1>
      )
    } else {
      header = (
        <h3
          css={css`
            margin-top: 0;
          `}
        >
        {headerLink}
        </h3>
      )
    }
    return (
      <div
        css={css`
          margin-left: auto;
          margin-right: auto;
          max-width: ${rhythm(24)};
          padding: ${rhythm(1.5)} ${rhythm(3 / 4)};
          color: #917c6fff;
        `}
      >
        {header}
        {children}
        <footer
          css={css`
            margin-top: ${rhythm(1.8)};
            text-align: center;
          `}
        >
          © {new Date().getFullYear()} William Dutcher 
        </footer>
      </div>
    )
  }
}

export default Layout

const logoQuery = graphql`
  query LogoQuery {
    makewerksLogo: file(absolutePath: { regex: "/makewerks-avatar.png/" }) {
      childImageSharp {
        fluid(maxWidth: 80) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    site {
      siteMetadata {
        author
        social {
          twitter
        }
      }
    }
  }
`
